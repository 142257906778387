@use "sass:map";
@use "sass:math";
@use "variables" as *;
@use "mixins" as *;

$cols: 12;
$tcols: 8;
$mcols: 6;
$gutter: 1.5rem;
$halfgutter: math.div($gutter, 2);
//$width: $maxwidth + $gutter;

.max-width {
  width: 100%;
  //max-width: $maxwidth + $gutter * 2;
  margin: 0 auto;
  padding: 0 $gutter;
}

.g-row {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  //flex: calc(100% + #{$gutter});

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &.center {
    justify-content: center;
  }
  //margin: 0 -#{$halfgutter};

  @include mq(phone) {
    .g-m-0 {
      display: none !important;
      flex: 0;
    }
    @for $i from 1 through $mcols {
      $w: math.div($i, $mcols) * 100%;
      $col: math.div($mcols, $i);
      $g: math.div(($col - 1) * $gutter, $col);
      .g-m-#{$i} {
        flex: 0 0 calc(#{$w} - #{$g});
        max-width: calc(#{$w} - #{$g});
      }
    }
  }

  @include mq(tablet) {
    .g-t-0 {
      display: none !important;
      flex: 0;
    }
    @for $i from 1 through $tcols {
      $w: math.div($i, $tcols) * 100%;
      $col: math.div($tcols, $i);
      $g: math.div(($col - 1) * $gutter, $col);
      .g-t-#{$i} {
        flex: 0 0 calc(#{$w} - #{$g});
        max-width: calc(#{$w} - #{$g});
      }
    }
  }

  @include mq(desktop) {
    .g-0 {
      display: none !important;
      flex: 0;
    }
    @for $i from 1 through $cols {
      $w: math.div($i, $cols) * 100%;
      $col: math.div($cols, $i);
      $g: math.div(($col - 1) * $gutter, $col);
      .g-#{$i} {
        flex: 0 0 calc(#{$w} - #{$g});
        max-width: calc(#{$w} - #{$g});
      }
    }
  }

  &.nogutter {
    @include mq(phone) {
      @for $i from 1 through $mcols {
        $w: math.div($i, $mcols) * 100%;
        .g-m-#{$i} {
          flex: 0 0 #{$w};
          max-width: #{$w};
        }
      }
    }

    @include mq(tablet) {
      @for $i from 1 through $tcols {
        $w: math.div($i, $tcols) * 100%;
        .g-t-#{$i} {
          flex: 0 0 #{$w};
          max-width: #{$w};
        }
      }
    }

    @include mq(desktop) {
      @for $i from 1 through $cols {
        $w: math.div($i, $cols) * 100%;
        .g-#{$i} {
          flex: 0 0 #{$w};
          max-width: #{$w};
        }
      }
    }
  }
}
