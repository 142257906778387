@use "../abstracts" as *;
@use "../base/typo" as *;

textarea {
  box-sizing: border-box;
  border: 0;
  border-radius: 0.75rem;
  font-family: $main;
  font-size: 1.75rem;
  line-height: 4rem;
  width: 100%;
  color: $black;
  padding: 0 1rem;
  //margin: 0 0 1rem 0;
  resize: none;
  &::placeholder {
    color: rgba($black, 0.6);
  }
}

button {
  border: 0;
}

form,
.form {
  position: relative;

  label.form-row {
    margin-bottom: 2rem !important;
    &.first {
      margin-bottom: 1rem !important;
    }
  }
  .form-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.has-error {
      .form-control input {
        border-color: $red !important;
      }
    }
    .form-label {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: $black;
      display: flex;
      justify-content: space-between;
      label {
        //opacity: 0.5;
      }
      .form-error {
        display: inline;
        color: $red;
      }
    }

    .form-control {
      position: relative;
      display: flex;
      align-items: center;
      //max-width: 366px;

      &.password {
        flex-direction: column;
      }

      &-prefix {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: -0.3px;
        display: flex;
        align-items: center;
        padding: 0 0 0 1.25rem;
        & + input {
          padding-left: 3.5rem;
        }
      }
    }

    .form-help {
      font-family: $input;
      font-size: 1rem;
      line-height: 1.25rem;
      letter-spacing: -0.4px;
      padding: 2rem 0 0;
      opacity: 0.6;
    }

    &.form-checkbox {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      &.has-error {
        .form-label {
          color: $red;
        }
      }
      .form-label {
        font-size: 0.9rem;
        font-weight: 400;
        order: 2;
        margin: 0;
        flex-direction: column;
        div {
          order: 2;
        }
        .form-error {
          order: 1;
          width: 100%;
          flex: 0 0 100%;
          display: none;
        }
      }
      .form-control {
        order: 1;
        input {
          //width: 2rem;
          //height: 2rem;
        }
      }
      .form-help {
        font-family: $main;
        letter-spacing: 0;
        font-size: 0.9rem;
        order: 3;
        flex: 0 0 100%;
        padding: 0.5rem 0 1rem;
      }
    }
    &.form-checkbutton {
      flex: 0 0 45%;
      width: auto;
      //margin: 0 auto;
      .form-control {
        text-align: center;
        .button {
          float: none;
          cursor: pointer;
          font-size: 1.5rem;
        }
      }
    }
  }
}

.custom-checkbox {
  background-color: $inputbg;
  display: block;
  position: relative;
  width: 2.3rem;
  height: 2.3rem;
  //background: none;
  // border: 1px solid $black;
  border-radius: 0;
  margin: 0 0.5rem 0 0;
  input {
    position: relative;
    opacity: 0;
    width: 2.3rem;
    height: 2.3rem;
    z-index: 2;
    padding: 0;
    margin: 0;
    &:checked + .custom-checkbox-check {
      opacity: 1;
    }
  }
  &-check {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    //background: $black;
    transition: opacity 0.2s ease-in-out;
    padding: 0.5rem;
    svg,
    img {
      width: 100%;
      height: 100%;
      color: $white;
    }
  }
}

.custom-radio {
  display: flex;
  
  //background: none;
  // border: 1px solid $black;
  border-radius: 0;
  margin: 0 1rem 0 0;
  input {
    position: relative;
    opacity: 0;
    width: 2.3rem;
    height: 2.3rem;
    z-index: 2;
    padding: 0;
    margin: 0;
    &:checked + .custom-radio-check {
      opacity: 1;
    }
  }
  &-input {
    background-color: $inputbg;
    position: relative;
    width: 2.3rem;
    height: 2.3rem;
    margin: 0 0.5rem 0 0;
  }
  &-label {
    display: block;
    line-height: 2.3rem;
  }
  &-check {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    //background: $black;
    transition: opacity 0.2s ease-in-out;
    padding: 0.5rem;
    svg,
    img {
      width: 100%;
      height: 100%;
      color: $white;
    }
  }
}
