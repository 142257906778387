@use "../abstracts" as *;
@use "../base/typo" as *;

.site-footer {
  background-color: $black-mid;
  color: $white;
  padding: 0 1.5rem;
  max-width: $maxwidth;
  margin: 0 auto;

  .content {
  }

  .cloud {
    animation-name: cloud;
    animation-duration: 8s;
    //animation-direction: alternate;
    animation-iteration-count: infinite;
    //animation-play-state: paused;
    //animation-timing-function: ease-in-out;
    &.two{
      opacity: 0;
      animation-delay: 0s;
      animation-duration: 10s;
    }
  }

  .homelink {
    background-color: $black-light;
    svg {
      height: 5.125rem;
      width: auto;
    }
  }

  .footnav {
    background-color: $black-mid;
    padding-top: 3rem;
    a {
      color: $gray;
      font-size: 1.25rem;
      display: block;
      font-style: italic;
      font-weight: 400;
    }
  }

  .bhs {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    img {
      height: 2.25rem;
      width: auto;
    }
  }

  @include mq(desktop) {
  }

  @keyframes cloud {
    0% {
      transform: translateX(-60%);
      opacity: 0;
    }
    50% {
      //transform: translateX(0%);
      opacity: 1;
    }
    100% {
      transform: translateX(60%);
      opacity: 0;
    }
  }
}
