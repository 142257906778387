@use "../abstracts" as *;

main {
  position: relative;
  padding: 0 1.5rem;
  max-width: $maxwidth;
  margin: 0 auto;

  section.content {
    padding-top: 90px;
    margin-top: -90px;
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: 1;
    &.active {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }

  .offer {
    background-color: $white-mid;
  }

  .expectations {
    background-color: $white-dark;
  }

  .applyform {
    background-color: $gray;
    a {
      color: $white;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .video{
    padding: 0;
    iframe{
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }
  }

  .moreinfo {
    color: $white;
    background-color: $gray-mid;
    a {
      font-size: 2.5rem;
      line-height: 2rem;
      font-weight: 600;
    }
  }

  .apply {
    color: $white;
    background-color: $gray-dark;
  }

  .anchor {
    padding-top: 100px;
    margin-top: -100px;
  }

  img {
    content-visibility: auto;
  }

  @include mq(desktop) {
    .applyform {
    }
  }
}

.site-header.mini{
  &+main{
    >section.content{
      //margin-top:-120px;
      //padding-top:120px;
    }
  }
}
