@use "../abstracts" as *;
@use "../base/typo" as *;

input,
.input {
  display: inline-block;
  box-sizing: border-box;
  border: 0;
  font-family: $main;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: $inputbg;
  border: 0;
  height: 2.3rem;
  width: 100%;
  color: $black;
  padding: 0 1rem;
  appearance: none;
  &::placeholder {
    font-size: 1rem;
    font-style: normal;
    color: rgba($black, 0.6);
    opacity: 0.6;
  }
  &:focus {
    background-color: $gray-mid !important;
  }

  &[type="tel"] {
    padding-left: 3.5rem;
  }
}