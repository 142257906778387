@use "../abstracts/variables" as *;
@use "../abstracts/mixins";

div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
main,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 0;
  font-style: normal;
  transition-delay: 86400s;
  -webkit-text-fill-color: $black-mid !important;
  -webkit-box-shadow: 0 0 0px 1000px $transparent inset !important;
  transition: background-color 86400s ease-in-out 0s;
}

hr {
  border:0;
  margin: 0.8rem 0;
  height: 1px;
  width: 100%;
  background: rgba($black-mid, 0.2);
  overflow: hidden;
}

picture,
img {
  display: block;
}

html {
  height: 100%;
  width: 100%;
  width: 100vw;
  max-width: 100vw;
  scroll-behavior: smooth;
  overflow-x: hidden;
  // font-size: 1.1111111vw;
  font-size: 16px;
  &.nosmooth {
    scroll-behavior: initial;
  }
}


/* @include mixins.mq(phone) {
  html {
    font-size: 4vw;
  }
}

@include mixins.mq(tablet) {
  html {
    font-size: 2vw;
  }
}

@include mixins.mq(large) {
  html {
    font-size: 20px;
  }
} */

@include mixins.mq(phone) {
  html {
    font-size: 16px;
  }
}


body {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  color: $black;
  background: $black-mid;
  * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    outline-style: none;
  }
}
