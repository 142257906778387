@use "../abstracts" as *;
@use "../base/typo" as *;

.button {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  font-family: $input;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  text-decoration: none !important;
  text-transform: uppercase;
  width: 100%;
  color: $white;
  background-color: $green;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    padding: 0.75rem 0;
  }

  em.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 1100ms ease-in-out;
    background-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
  }
  
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  &.dark {
    color: $white;
    background-color: $black-mid;

    &:focus,
    &:hover {
      background-color: $black-light;
    }

    &.active,
    &:active {
      background-color: $black;
    }
  }

  &:focus,
  &:hover {
    background-color: $green-mid;
  }

  &.active,
  &:active {
    background-color: $green-dark;
  }
}
