@use "../abstracts" as *;
@use "../base/typo" as *;

.site-header {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: $red;

  .bhs {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black-mid;
    padding: 0.688rem 1.5rem;

    img {
      height: auto;
      width: 152px;
    }
  }

  &.mini {
    .carousel {
      z-index: 1000;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      //left: calc(50% - 456px);
      //right: calc(50% - 456px);
      top: 0;
      width: calc(100% - 3rem);
      max-width: 912px;
      margin: 0 auto;
      &-item {
        height: 90px;
        padding: 0;
        .holder {
          .swipe {
            opacity: 0;
          }
          svg,
          .h3 {
            display: none;
          }
          .mini {
            transition: opacity 0.3s ease-in-out;
            //opacity: 1;
          }
          p {
            display: none;
          }
        }
        &.active {
          .holder {
            .mini {
              opacity: 1;
            }
          }
        }
      }
    }
    & + main {
      padding-top: 28rem;
    }
    &.fix {
      .bhs {
        display: none;
      }
      & + main {
        padding-top: 5.5rem;
      }
    }
  }

  .carousel {
    margin: 0 auto;
    width: calc(100% - 3rem);
    max-width: 912px;
    &-clip {
      overflow: visible;
    }
    &-prev,
    &-next,
    &-dots {
      display: none;
    }
    &-item {
      //transition: all 0.3s ease-in-out;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 28rem;
      padding: 2rem 0 4rem;
      text-decoration: none;
      pointer-events: none;
      .holder {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        max-width: 100%;
        margin: 0 auto;
        overflow: hidden;

        .mini {
          position: absolute;
          opacity: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        svg {
          width: auto;
          height: 160px;
          margin-bottom: 1rem;
        }
        .h3 {
          text-transform: uppercase;
          text-align: left;
          margin: 0;
          color: $black;
          max-width: 16rem;
        }
        .swipe {
          margin: 3rem 0 0.75rem 0;
          //transition: opacity 0.3s ease-in-out;
          animation-name: swipe;
          animation-duration: 1s;
          animation-direction: alternate;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
        }
        p,
        .arrow {
          display: none;
        }
        .right,
        .left {
          // transition: all 0.3s ease-in-out;
          // transform-origin: center;
          animation-name: rightleft;
          animation-duration: 2s;
          animation-direction: alternate;
          animation-iteration-count: infinite;
          animation-play-state: paused;
        }
        .left {
          animation-name: leftright;
          // animation-delay: 0.2s;
        }

        .car {
          animation-name: car;
          animation-duration: 2s;
          animation-direction: alternate;
          animation-iteration-count: infinite;
          animation-play-state: paused;
          animation-timing-function: ease-in-out;
        }

        .wheel {
          animation-name: wheel;
          animation-duration: 1s;
          animation-direction: alternate;
          animation-iteration-count: infinite;
          animation-play-state: paused;
        }

        .parallax-anim {
          position: relative;
          width: calc(100% - 3rem);
          overflow: hidden;

          svg {
            position: relative;
            z-index: 1;
            left: 50%;
            transform: scale(0.8) translateX(-50%);
          }
          &:before {
            content: "";
            position: absolute;
            z-index: 2;
            background: linear-gradient(90deg, currentColor, $transparent);
            width: 100px;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
          }
          &:after {
            content: "";
            z-index: 2;
            position: absolute;
            background: linear-gradient(90deg, $transparent, currentColor);
            width: 100px;
            height: 100%;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }

        .reflection {
          animation-name: reflection;
          animation-duration: 10s;
          //animation-direction: forwards;
          animation-iteration-count: infinite;
          animation-play-state: paused;
          animation-timing-function: linear;
        }

        .layer-x,
        .layer-1,
        .layer-2,
        .layer-3,
        .layer-5 {
          animation-name: layeranim;
          animation-duration: 30s;
          //animation-direction: forwards;
          animation-iteration-count: infinite;
          animation-play-state: paused;
          animation-timing-function: linear;
        }

        .clone {
          animation-name: cloneanim;
        }

        .layer-x {
          animation-duration: 45s;
        }
        .layer-1 {
          animation-duration: 30s;
        }

        .layer-2 {
          animation-duration: 15s;
        }
        .layer-3 {
          animation-duration: 10s;
        }

        .layer-5 {
          animation-duration: 5s;
        }
      }
      &.active {
        pointer-events: auto;

        .holder {
          .reflection {
            animation-play-state: running;
          }
          .swipe {
            animation-play-state: running;
          }
          .car {
            animation-play-state: running;
          }
          .wheel {
            animation-play-state: running;
          }
          .left {
            animation-play-state: running;
          }
          .right {
            animation-play-state: running;
          }
          .layer-x,
          .layer-1,
          .layer-2,
          .layer-3,
          .layer-4,
          .layer-5 {
            animation-play-state: running;
          }
        }
      }
    }
  }

  .ckat {
    background-color: $red;
    color: $red;
    /*.str0 {
      stroke: black;
      stroke-width: 0.08;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 22.9256;
    }
    .fil1 {
      fill: none;
    }
    .fil2 {
      fill: #e31e25;
    }
    .fil3 {
      fill: white;
    }
    .fil0 {
      fill: white;
      fill-rule: nonzero;
    }*/
  }

  .bkat {
    background-color: $green;
    color: $green;
    /*.str0 {
      stroke: black;
      stroke-width: 0.08;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 22.9256;
    }
    .fil1 {
      fill: none;
    }
    .fil2 {
      fill: $green;
    }
    .fil3 {
      fill: white;
    }
    .fil0 {
      fill: white;
      fill-rule: nonzero;
    }*/
  }

  .belf {
    background-color: $red-mid;
    color: $red-mid;
    /*.str0 {
      stroke: black;
      stroke-width: 0.09;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 22.9256;
    }

    .fil1 {
      fill: none;
    }

    .fil2 {
      fill: #c02222;
    }

    .fil0 {
      fill: white;
      fill-rule: nonzero;
    }*/
    .holder {
      .parallax-anim {
        &:before {
          background: linear-gradient(90deg, $red-mid, $transparent);
        }
        &:after {
          background: linear-gradient(90deg, $transparent, $red-mid);
        }
      }
    }
  }

  .nemz {
    background-color: $red-dark;
    color: $red-dark;
    /*.str0 {
      stroke: black;
      stroke-width: 0.07;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 22.9256;
    }
    .fil1 {
      fill: none;
    }
    .fil2 {
      fill: #9d231f;
    }
    .fil0 {
      fill: white;
      fill-rule: nonzero;
    }*/
    .holder {
      .parallax-anim {
        &:before {
          background: linear-gradient(90deg, $red-dark, $transparent);
        }
        &:after {
          background: linear-gradient(90deg, $transparent, $red-dark);
        }
      }
    }
  }

  @include mq(desktop) {
    &.mini {
      .carousel-item {
        .holder{

          .arrow{
            display: none;
          }
        }
      }
      & + main {
        padding-top: calc(100vh - 100px);
      }
    }
    .carousel {
      &-clip {
        max-width: calc($maxwidth - 3rem);
        margin: 0 auto;
      }
      &-item {
        user-select: none;
        -webkit-user-drag: none;
        height: calc(100vh - 100px);
        justify-content: center;
        .holder {
          position: relative;
          max-width: 19rem;
          overflow: visible;
          // max-width: 18rem;
          .h3 {
            max-width: none;
            width: 100%;
          }
          .swipe {
            opacity: 0;
            height: 0;
            margin: 0;
          }
          p {
            padding: 1rem 0;
            margin: 0;
            align-self: flex-start;
            display: block;
            color: $white;
            font-weight: 400;
            width: 17rem;
          }
          .arrow {
            display: inline-block;
            align-self: flex-start;
          }
          .parallax-anim {
            width: auto;
            svg {
              transform: translateX(-50%);
            }
          }
        }
        &.active{
          .arrow{
            animation-name: arrowdown;
            animation-duration: 1s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
          }
        }
        /* &:not(.active){
          width: calc((100vw - 960px) / 2);
        } */
      }
    }
  }

  @keyframes leftright {
    0% {
      transform: translateX(-5%);
    }
    100% {
      transform: translateX(1%);
    }
  }

  @keyframes rightleft {
    0% {
      transform: translateX(4%);
    }
    100% {
      transform: translateX(-2%);
    }
  }

  @keyframes car {
    0% {
      transform: translate(0%, 0%);
    }

    0% {
      transform: translate(4%, -0.5%);
    }

    100% {
      transform: translate(8%, 0%);
    }
  }
  /* @keyframes car {
    0% {
      transform: translate(0%, 0%);
    }

    20% {
      transform: translate(1%, -0.25%);
    }

    40% {
      transform: translate(1.5%, 0%);
    }

    60% {
      transform: translate(2%, -0.5%);
    }

    80% {
      transform: translate(2.5%, 0%);
    }

    100% {
      transform: translate(3.5%, -0.25%);
    }
  } */

  @keyframes wheel {
    0% {
      transform: translateY(0%);
    }

    20% {
      transform: translateY(0.25%);
    }

    40% {
      transform: translateY(0%);
    }

    60% {
      transform: translateY(-0.25%);
    }

    80% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(0.25%);
    }
  }

  /* @keyframes car {
    0% {
      transform: translateX(0%);
    }
    70% {
      transform: translateX(200%);
      opacity: 1;
    }
    71% {
      transform: translateX(200%);
      opacity: 0;
    }
    72% {
      transform: translateX(-100%);
      opacity: 0;
    }
    73% {
      transform: translateX(-100%);
      opacity: 1;
    }
    100% {
      transform: translateX(0%);
    }
  } */

  @keyframes swipe {
    0% {
      // transform: translateX(-10%);
    }
    100% {
      // transform: translateX(10%);
      transform: scale(1.1);
    }
  }

  @keyframes arrowdown {
    0% {
      // transform: translateX(-10%);
    }
    100% {
      // transform: translateX(10%);
      transform: translateY(-100%);
    }
  }

  @keyframes layeranim {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-150%);
    }
  }

  @keyframes cloneanim {
    0% {
      transform: translateX(150%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes clone2 {
    0% {
      opacity: 1;
      transform: translateX(150%);
    }
    98% {
      opacity: 1;
      transform: translateX(-150%);
    }
    99% {
      opacity: 0;
      transform: translateX(-150%);
    }
    100% {
      opacity: 0;
      transform: translateX(150%);
    }
  }
  @keyframes reflection {
    0% {
      transform: translateX(0.1%);
    }
    50% {
      transform: translateX(-0.1%);
    }
    100% {
      transform: translateX(0.1%);
    }
  }
}
