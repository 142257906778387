@use "../abstracts" as *;
@use "../base/typo" as *;

.select {
  display: inline-block;
  font-family: $main;
  position: relative;
  width: 100%;
  //width: 20em;

  &.selected {
    .select-title {
      color: $black;
    }
  }

  &-title {
    border: 0;
    cursor: pointer;
    //text-transform: uppercase;
    text-align: left;
    line-height: 2.3rem;
    height: 2.3rem;
    width: 100%;
    color: $black;
    padding: 0;
    position: relative;
    overflow: hidden;
    z-index: 2;
    input {
      padding-left: 2.3rem;
      pointer-events: none;
    }
    &-text {
      max-width: calc(100% - 3rem);
      display: block;
      word-wrap: break-word;
      overflow: hidden;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 2.3rem;
      width: 2.3rem;
      //background-color: $gray-mid;
      background-image: url("../svg/arrow-down.svg");
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: 50% 50%;
      transition: transform 0.3s ease-in-out;
    }
  }

  &.focus {
    z-index: 1000;
  }

  &.open {
    .select {
      &-title:after {
        transform: scale(-1, -1);
      }
      &-list {
        transform: translateY(0);
        //box-shadow: 0.25rem 0.25rem 0.25rem rgba($black, 0.25);
      }
      &-mask {
        height: auto;
        max-height: 80vh;
        //transition: height 0.3s ease-in-out 0s;
      }
    }
  }

  &-mask {
    overflow: hidden;
    height: auto;
    max-height: 0;
    width: 100%;
    //width: calc(100% + 2rem);
    position: absolute;
    //margin-top: -1rem;
    //padding: 1rem;
    //margin: -1rem -1rem 0;
    z-index: 1;
    transition: max-height 0.3s ease-in-out;
  }

  &-list {
    background: $inputbg;
    //border-radius: 0.75rem;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 999;
    transform: translateY(calc(-100% - 3rem));
    transition: all 0.3s ease-in-out;
    overflow-y: hidden;
    box-shadow: 0 0 0 rgba($black, 0);
    //height: 50vh;
    //max-height: 50vh;
    .cscroll-container {
      max-height: 50vh !important;
    }
  }
  &-item {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 0.5rem;
    font-size: 1rem;
    line-height: 2.3rem;
    color: $black;
    //border-bottom: 1px solid $black;
    transition: background-color 0.3s ease-in-out;

    &.disabled {
      display: none;
    }

    &-group {
      pointer-events: none;
    }

    &-content {
      strong {
        display: block;
        pointer-events: unset;
      }
      span {
        display: block;
        pointer-events: unset;
      }
      em {
        font-style: italic;
        font-size: 1.5rem;
        color: $black;
        pointer-events: unset;
      }
    }
    &-control {
      /*position: relative;
      min-width: 1.5rem;
      height: 1.5rem;
      */
      height: 2.3rem;
      width: 100%;
    }

    &-checkbox {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      margin: 0;
      z-index: 10;
      &-check {
        position: absolute;
        z-index: 1;
        display: block;
        right: 0;
        top: 0;
        width: 2.3rem;
        min-width: 2.3rem;
        height: 2.3rem;
        // border-radius: 50%;
        //border: 2px solid $black;
        //transform: translate(-50%, -50%);

        &:after {
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%;
          content: "";
          display: block;
          width: 0;
          min-width: 0;
          height: 0;
          //border-radius: 50%;
          //background-color: $darkrose;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease-in-out;
          background-image: url("../svg/x.svg");
          background-repeat: no-repeat;
          background-size: 80%;
          background-position: 50% 50%;
        }
      }
      &:checked {
        & + .select-item-checkbox-check:after {
          width: 1.3rem;
          min-width: 1.3rem;
          height: 1.3rem;
        }
      }
    }

    &:hover {
      background-color: $gray-mid;
    }

    &.is-selected {
      background-color: $black;
    }
  }

  &-optgroup {
    color: $black;
    cursor: default;
    padding: 1em 0.5em;
    text-align: center;
  }
}
