@use "../abstracts" as *;

.carousel {
  position: relative;
  &-clip {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &-stripe {
    transition: transform 0.3s ease-in-out;
    height: 100%;
    width: auto;
    white-space: nowrap;
    font-size: 0;
    a {
      pointer-events: auto;
    }
    &.dragging a {
      background: rgba(red, 0.4);
      //pointer-events: none;
    }
  }

  &-item {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: initial;
    white-space: normal;
    user-select: none;
    vertical-align: middle;
    backface-visibility: visible;
    transform: translateZ(0);
    cursor: move;
    &:after{
      content: '';
      display: block;
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    a,
    img {
      user-select: none;
      user-drag: none;
      app-region: no-drag;
      user-select: none;
      -webkit-user-drag: none;
    }
  }

  /*&-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 1.6rem;
    bottom: 1.6rem;
    right: 1.6rem;
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      background-color: rgba($black-mid, 0.3);
      z-index: 1;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
      font-size: 0;
      background: none;
      width: 100%;
      height: 1px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      position: relative;
      &.active {
        &:before {
          content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
          background-color: $black-mid;
          z-index: 1;
        }
      }
    }
  }*/
  &-dots {
    display: flex;
    button {
      background: $gray;
      border: 1px solid $gray-dark;
      padding: 0;
      margin-right: 1rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      img{
        width: 4rem;
        height: 4rem;
        margin: 0.5rem;
      }
      &.active {
        border: 1px solid $black;
      }
    }
  }
  &-prev,
  &-next {
    display: none;
  }

  @include mq(desktop) {
    /*&-dots {
      left: 5rem;
      bottom: 5rem;
      right: 5rem;
    }*/
  }
}
