@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;
@use "fonts";

@import url("https://use.typekit.net/tkg4lts.css");

$main: "Source Sans Pro";
$input: "Source Sans Pro";
$title: "Source Sans Pro";

body {
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: $main, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.h1 {
  font-family: $title;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
}

.h2 {
  font-family: $title;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
}

.h3 {
  font-family: $title;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.large {
  font-family: $title;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

em {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

ul {
  padding: 0;
  margin: 0;
  li {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding-left: 1rem;
    position: relative;
    &:before {
      content: "-";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }
}

strong {
  font-weight: 600;
}

a {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

@include mq(desktop) {
}
