$breakpoints: (
  phone: (
    min: 100px,
    max: 500px,
  ),
  tablet: (
    min: 501px,
    max: 960px,
  ),
  desktop: (
    min: 961px,
    max: 5000px,
  ),
  medium: (
    min: 1441px,
    max: 5000px,
  ),
  large: (
    min: 1920px,
    max: 5000px,
  ),
);

$maxwidth: 60rem;
$blockmargin: 4rem;

$transparent: rgba(0, 0, 0, 0);

$white: rgba(254, 254, 254, 1);
$white-mid: rgba(236, 236, 236, 1);
$white-dark: rgba(210, 210, 210, 1);

$inputbg: rgba(157, 157, 157, 1);

$gray: rgba(179, 179, 179, 1);
$gray-mid: rgba(114, 114, 114, 1);
$gray-dark: rgba(91, 91, 91, 1);

$black: rgba(0, 0, 0, 1);
$black-mid: rgba(42, 42, 42, 1);
$black-light: rgba(66, 66, 66, 1);

$red: rgba(227, 30, 37, 1);
$red-mid: rgba(192, 34, 34, 1);
$red-dark: rgba(157, 35, 31, 1);

$green: rgba(175, 203, 31, 1);
$green-mid: rgba(163, 188, 33, 1);
$green-dark: rgba(150, 174, 33, 1);

$blue: rgba(0, 160, 227, 1);
$blue-mid: rgba(0, 130, 201, 1);
$blue-dark: rgba(0, 108, 181, 1);
