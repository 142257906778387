@use "../abstracts" as *;
@use "../base/typo" as *;

.layer {
  position: fixed;
  z-index: 10;
  // top: $mtop;
  top: 0;
  right: 0;
  width: 100%;
  max-height: 100%;
  padding: 1.6rem;
  background: $white;
  display: flex;
  flex-direction: column;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;

  &[hidden] {
    transform: translateY(-100%);
  }

  &.relative{
    position: relative;
  }

  .content {
    height: 0;
    flex: 1;
    margin-bottom: 1rem;
    &:last-child{
      margin-bottom: 0
    }
  }

  .greeting {
    display: block;
    font-family: $title;
    font-size: 1.75rem;
    line-height: 3rem;
    font-weight: normal;
  }

  .title {
    display: block;
    font-family: $main;
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: normal;
    //text-transform: uppercase;
    letter-spacing: -0.4px;
    margin-bottom: 1rem;
  }

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    padding: 1rem;
    img {
      width: 1.313rem;
      height: 1.313rem;
    }
  }

  @include mq(tablet) {
    //width: max(75%, 26rem);
    width: 30rem;
    // top: $dtop;
  }

  @include mq(desktop) {
    padding: 2rem;
    bottom: 0;
    // top: $dtop;
    width: max(33%, 26rem);
    max-width: 30rem;
    transform: translateX(0);

    &[hidden] {
      transform: translateX(100%);
      opacity: 0;
    }

    .greeting {
      font-size: 2.25rem;
      line-height: 4rem;
      padding-top: 4.5rem;
      letter-spacing: -1.4px;
      margin-bottom: 0.75rem;
    }
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 100%;
  z-index: 9;
  opacity: 0;
  background-color: rgba($black, 0.5);
  transition: opacity 0.3s ease-in-out;
  &.show {
    opacity: 1;
    bottom: 0;
  }
}
