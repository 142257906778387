@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

/* body {
  background-image: url("../img/bhsbg.png");
  background-position: 0 110.75%;
  background-size: 100% auto;
} */

.disabled {
  opacity: 0.6 !important;
  pointer-events: none !important;
  cursor: default !important;
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $white;
  padding: 1.5rem;

  &.text{
    h1{
      margin-bottom: 2rem;
    }
    ul li{
      font-weight: 400;
    }
  }
}

@include mq(tablet) {
}

@include mq(desktop) {
}
