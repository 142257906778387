@use "../abstracts" as *;
@use "../base/typo" as *;

.modal-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, bottom 0s ease-in-out 0.3s;
  z-index: 1000;
  &.show {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    bottom: 0;
  }
}

.modal-layer {
  position: relative;
  background: $blue;
  color: $white;
  padding: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 260px;

  &.default{
    .modal-layer-close{
      display: none;
    }
  }
  
  &.error{
    background: $red;
    .modal-layer-close{
      svg{
        color: $red-mid;
      }
    }
    .modal-layer-button{
      &:hover{
        .svgbg{
          fill: $red-mid;
        }
      }
    }
  }

  &.success{
    background: $green;
    color: $black-mid;
    .modal-layer-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0
      
    }
    .modal-layer-close{
      svg{
        color: $green-mid;
      }
    }
    .modal-layer-button{
      &:hover{
        .svgbg{
          fill: $green-mid;
        }
      }
    }
  }
  
  .hidden{
    display: none;
  }
  
  &-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-height: 320px;
    max-width: 220px;
  }
  
  &-close{
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
    svg{
      display: block;       
      color: $blue-mid;
    }
  }

  &-title {
    //text-align: center;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.25rem;
    margin-bottom: 1rem;
  }

  &-content {
    //text-align: center;
    margin-bottom: 2rem;
    display: none;
  }

  &-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-button{
    //margin: 0 0.5rem;
    width: auto;
    margin-right: 1rem;
    white-space: nowrap;
    background: none;
    &:last-child{
      margin: 0
    }
    &:active,
    &:focus,
    &:hover{
      background: inherit;
      .svgbg{
        fill: $blue-mid;
      }
    }
  }
  @include mq(tablet) {
    max-width: 60vw;  
    .modal-layer-buttons{
      flex-wrap: nowrap;
    }
  } 

  @include mq(desktop) {
    max-width: 570px;
    
    .modal-layer-buttons{
      flex-wrap: nowrap;
    }
  }
}
