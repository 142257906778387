@use 'sass:map';
@use "variables";

@mixin mq($key) {
    $bp: map.get(variables.$breakpoints, $key);
    $min: map.get($bp, 'min');
    $max: map.get($bp, 'max');

    @media screen and (min-width: $min) and (max-width: $max) {
        @content;
    }
}